import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/graphic-designing.jpg";
import basebg from "../../assets/images/resource/base.png";
import Contact from '../HomeOne/contact';

const GraphicDesigning = () => {
    pageTitle('Graphic Designing');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Logic to handle form submission
        console.log('Form submitted:', formData);

        // Clear the form fields after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Web Development', slug: '/web-development' },
        { id: 2, title: 'Digital Marketing', slug: '/digital-marketing' },
        { id: 3, title: 'SEO Marketing', slug: '/seo-marketing' },
        { id: 4, title: 'Graphic Designing', slug: '/graphic-designing' },
      ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/graphic-designing' ? 'active' : ''}>
                <Link to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (
        <>
            <HeaderOne></HeaderOne>
            {/* <BreadCrumb></BreadCrumb> */}
            <div className="service__details p_relative">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 hiding-image">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4>All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                {/* <div className="sidebar__widget_network  hiding-image" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us for Graphic Design</h3>
                                    <div className="services__icon">
                                        <i className="icon-20"></i>
                                    </div>
                                    <p>Need help? Email us:</p>
                                    <h4><a href="mailto:info@siteguiders.com">info@siteguiders.com</a></h4>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                            <div>
                                <h2 style={{ marginBottom: '20px' }}>Graphic Designing</h2>
                            </div>
                                <figure>
                                    <img src={service} alt="Graphic Designing" />
                                </figure>
                                <h2>Bring Your Ideas to Life with <span>Graphic Designing</span></h2>
                                <p>Our graphic designing services help you create visually appealing designs that capture your audience's attention. Whether you need logos, brochures, business cards, or social media graphics, we've got you covered. Our designers are skilled in turning your ideas into reality, ensuring that your brand stands out.</p>
                                <p>We work closely with you to understand your vision and create designs that reflect your brand's personality. From color schemes to typography, we pay attention to every detail to make sure your designs are not only beautiful but also effective in conveying your message.</p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Logo Design</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Brochure Design</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Business Cards</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Social Media Graphics</Link></li>
                                    </div>
                                </div>
                                <p>Our goal is to help you create designs that resonate with your audience and leave a lasting impression. Let's work together to bring your ideas to life and make your brand memorable.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact></Contact>
            <FooterOne></FooterOne>
        </>
    );
};

export default GraphicDesigning;
