import React from "react";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";

const TermsAndConditions = () => {
  const containerStyle = {
    padding: "60px 20px",
    maxWidth: "800px",
    margin: "0 auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#1E1E1E",
    color: "#CCCCCC",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
  };

  const headingStyle = {
    color: "#FFFFFF",
    marginBottom: "20px",
    fontSize: "35px",
    borderBottom: "2px solid #555",
    paddingBottom: "10px",
  };

  const subHeadingStyle = {
    color: "#FFFFFF",
    marginBottom: "15px",
    fontSize: "20px",
  };

  const paragraphStyle = {
    marginBottom: "20px",
    lineHeight: "1.8",
    fontSize: "16px",
  };

  const pageStyle = {
    paddingTop: "100px", // Adjusts spacing from the top for the header
    paddingBottom: "100px", // Adjusts spacing from the bottom for the footer
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      <div style={pageStyle}>
        <div style={containerStyle}>
        <h1 style={headingStyle}><b class="responsive-heading">Terms and Conditions</b></h1>

          <section>
            <h2 style={subHeadingStyle}>Welcome to Site Guiders!</h2>
            <p style={paragraphStyle}>
              These terms and conditions outline the rules for using the Site
              Guiders website, accessible at siteguiders.com. By using this
              website, we assume you agree to these terms. If you don’t agree
              with all the terms, please refrain from using Site Guiders.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Understanding the Basics</h2>
            <p style={paragraphStyle}>
              The terms “Client,” “You,” and “Your” refer to you, the person
              visiting this website. “The Company,” “Ourselves,” “We,” “Our,”
              and “Us” refer to Site Guiders. “Party,” “Parties,” or “Us” refer
              to both the Client and ourselves.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Cookies</h2>
            <p style={paragraphStyle}>
              We use cookies on Site Guiders. By using the website, you agree to
              our use of cookies as per our Privacy Policy. Cookies help enhance
              the user experience.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Ownership and Usage License</h2>
            <p style={paragraphStyle}>
              Unless stated otherwise, Site Guiders and/or its licensors own the
              intellectual property rights for all material on the website. You
              can access this material for personal use but must not republish,
              sell, rent, sublicense, reproduce, duplicate, or redistribute it.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>User Responsibilities</h2>
            <p style={paragraphStyle}>
              Commenting: Some parts of the website allow users to post opinions
              and information. Site Guiders doesn’t filter or review comments
              before they appear. Comments express users’ views, not necessarily
              Site Guiders’. Your Warranties: By posting comments, you declare
              that you have the right to do so, possess all necessary licenses
              and consents, and that your comments are not unlawful or
              infringing on others’ rights. License to Site Guiders: You grant
              Site Guiders a license to use, reproduce, and edit your comments.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>External Links</h2>
            <p style={paragraphStyle}>
              Site Guiders may contain links to external websites. We hold no
              responsibility for the content on these sites. Links don’t imply
              endorsement, and we aren’t liable for any harm from visiting
              external sites.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Linking to Our Website</h2>
            <p style={paragraphStyle}>
              If you want to link to Site Guiders, ensure it’s done in a
              non-deceptive way. Approved organizations may hyperlink using our
              corporate name or URL. However, using our logo without permission
              is not allowed.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>IFrames</h2>
            <p style={paragraphStyle}>
              Creating frames around our webpages that alter the website’s
              appearance requires prior approval.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Your Privacy</h2>
            <p style={paragraphStyle}>
              Refer to our Privacy Policy for information on how we handle your
              privacy.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Reservation of Rights</h2>
            <p style={paragraphStyle}>
              Site Guiders reserves the right to request removal of links to our
              website. We may amend these terms and conditions at any time.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Removal of Links from Our Website</h2>
            <p style={paragraphStyle}>
              If you find offensive links on our site, contact us. While we’ll
              consider removal requests, we’re not obligated to do so.
            </p>
          </section>

          <section>
            <h2 style={subHeadingStyle}>Disclaimer</h2>
            <p style={paragraphStyle}>
              By using Site Guiders, you agree to these terms and conditions. We
              may update them, so check regularly so that you can stay tuned
              with our latest updates.
            </p>
          </section>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default TermsAndConditions;