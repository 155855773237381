import React from "react";
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: "60px 20px",
    maxWidth: "800px",
    margin: "0 auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#060e26",
    border: "1px solid",
    color: "#CCCCCC",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
  };

  const headingStyle = {
    color: "#FFFFFF",
    marginBottom: "20px",
    fontSize: "35px",
    borderBottom: "2px solid #555",
    paddingBottom: "10px",
  };

  const subHeadingStyle = {
    color: "#FFFFFF",
    marginBottom: "15px",
    fontSize: "22px",
  };

  const paragraphStyle = {
    marginBottom: "20px",
    lineHeight: "1.8",
    fontSize: "16px",
  };

  const pageStyle = {
    paddingTop: "100px", // Adjusts spacing from the top for the header
    paddingBottom: "100px", // Adjusts spacing from the bottom for the footer
  };

  return (
    <>
        <HeaderOne></HeaderOne>
        <div style={pageStyle}>
            <div style={containerStyle}>
                <h1 style={headingStyle}><b class="responsive-heading">Privacy Policy</b></h1>

        <section>
          <h2 style={subHeadingStyle}>Our Commitment to Your Privacy</h2>
          <p style={paragraphStyle}>
            At SiteGuiders, safeguarding your privacy is a top priority for us.
            This Privacy Policy outlines the information we collect and how we
            utilize it to ensure a transparent and secure experience for our
            visitors.
          </p>
          <p style={paragraphStyle}>
            If you have any questions or need further clarification about our
            Privacy Policy, please don’t hesitate to reach out to us.
          </p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Scope of the Privacy Policy</h2>
          <p style={paragraphStyle}>
            This Privacy Policy specifically applies to online activities on our
            website, siteguiders.com. It governs the information shared and/or
            collected through this website and does not extend to any data
            collected offline or through other channels.
          </p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Your Consent Matters</h2>
          <p style={paragraphStyle}>
            By using our website, you’re agreeing to our Privacy Policy. It’s
            essential to understand and accept its terms for a smooth and secure
            interaction with our platform.
          </p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Information Collection</h2>
          <p style={paragraphStyle}>
            The types and reasons behind the personal information we ask you to
            provide will always be clear when we request it. If you directly
            contact us, we may receive additional details like your name, email,
            phone number, and message contents.
          </p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>How We Use Your information</h2>
          <p style={paragraphStyle}>We use the collected information to: Provide, operate, and maintain our website.
            Enhance, personalize, and expand our website’s features. Understand and analyze how you use our website. Develop new products, services, and functionality.
            Communicate directly or through partners for customer service, updates, and marketing purposes.Send emails.
          </p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Log Files</h2>
          <p style={paragraphStyle}>Like many websites, Site Guiders uses log 
            files to gather data such as IP addresses, browser type, ISP, date and time stamp, 
            and pages visited. This information aids in analyzing trends, administering the site, and understanding user demographics.</p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Cookies and Web Beacons</h2>
          <p style={paragraphStyle}>Site Guiders employs cookies to store visitor preferences and optimize their 
            experience by customizing webpage content based on browser type and other information.</p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Third-Party Privacy Policies</h2>
          <p style={paragraphStyle}>Site Guiders’ Privacy Policy doesn’t extend to other advertisers or websites.
             Check the respective Privacy Policies of third-party ad servers for detailed information,
              including opt-out options.</p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Your Privacy Rights</h2>
          <p style={paragraphStyle}>Under CCPA, Every consumers have the right to request their personal data disclosure,
             deletion, or cessation of sale. For GDPR, users have rights such as access, rectification, erasure,
              restriction, objection, and data portability.</p>
        </section>

        <section>
          <h2 style={subHeadingStyle}>Children’s Information</h2>
          <p style={paragraphStyle}>Site Guiders prioritizes children’s internet safety. We do not knowingly 
            collect personal information from children under 13. If a child provides such information, please 
            contact us for prompt removal.<br></br>
            This Privacy Policy is subject to periodic updates, and we recommend reviewing it regularly for any changes. Your continued use of the Site Guiders website constitutes acceptance of these terms.</p>
        </section>
      </div>
    </div>
    <FooterOne></FooterOne>
    </>
  );
};

export default PrivacyPolicy;
