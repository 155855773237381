import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/seo.jpg";
import basebg from "../../assets/images/resource/base.png";
import Contact from '../HomeOne/contact';

const SeoMarketing = () => {
    pageTitle('SEO Marketing');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Logic to handle form submission
        console.log('Form submitted:', formData);

        // Clear the form fields after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Web Development', slug: '/web-development' },
        { id: 2, title: 'Digital Marketing', slug: '/digital-marketing' },
        { id: 3, title: 'SEO Marketing', slug: '/seo-marketing' },
        { id: 4, title: 'Graphic Designing', slug: '/graphic-designing' },
      ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/seo-marketing' ? 'active' : ''}>
                <Link to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (
        <>
            <HeaderOne></HeaderOne>
            {/* <BreadCrumb></BreadCrumb> */}
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 hiding-image">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4>All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                {/* <div className="sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us For Any SEO Needs</h3>
                                    <div className="services__icon">
                                        <i className="icon-20"></i>
                                    </div>
                                    <p>Need help? Email us:</p>
                                    <h4><a href="mailto:info@siteguiders.com">info@siteguiders.com</a></h4>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                            <div>
                                <h2 style={{ marginBottom: '20px' }}>SEO Marketing</h2>
                            </div>
                                <figure>
                                    <img src={service} alt="SEO Marketing" />
                                </figure>
                                <h2>Boost Your Online Presence with <span>SEO Marketing</span></h2>
                                <p>Our SEO Marketing services are designed to help your business rank higher on search engines like Google. We use a combination of keyword research, on-page optimization, and backlink building to increase your website’s visibility. This leads to more traffic, higher engagement, and better conversions.</p>
                                <p>Our team tailors each SEO strategy to meet your specific business needs, ensuring that your site performs well in search results. Whether you’re targeting local customers or a global audience, our SEO solutions will help you achieve your goals.</p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Keyword Research</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> On-Page SEO</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Link Building</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Local SEO</Link></li>
                                    </div>
                                </div>
                                <p>We understand that every business is unique. That’s why our SEO experts work closely with you to develop a custom strategy that delivers results. Let us help you attract more visitors and turn them into loyal customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact></Contact>
            <FooterOne></FooterOne>
        </>
    );
};

export default SeoMarketing;
