import React from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
// import { Link } from 'react-router-dom';
// import portfolio1 from "../../assets/images/resource/portfolio-01.jpg";
// import portfolio2 from "../../assets/images/resource/portfolio-02.jpg";
// import portfolio3 from "../../assets/images/resource/portfolio-03.jpg";
// import portfolio4 from "../../assets/images/resource/portfolio-04.jpg";
// import portfolio5 from "../../assets/images/resource/portfolio-05.jpg";
// import portfolio6 from "../../assets/images/resource/portfolio-06.jpg";
// import portfolio7 from "../../assets/images/resource/portfolio-07.jpg";
// import portfolio8 from "../../assets/images/resource/portfolio-08.jpg";
import two from "../../assets/images/resource/fitness.jpg";
import two2 from "../../assets/images/resource/jdmaxx.jpg";
import two3 from "../../assets/images/resource/flowersnow.jpg";
import two4 from "../../assets/images/resource/astons.jpg";
import two5 from "../../assets/images/resource/lollyprop.jpg";
import two6 from "../../assets/images/resource/ameeneng.jpg";
import two7 from "../../assets/images/resource/teganlighting.jpg";
import two8 from "../../assets/images/resource/cliniximagerie.jpg";
import two9 from "../../assets/images/resource/cateslondon.jpg";
import two10 from "../../assets/images/resource/uccbh.jpg";
import two11 from "../../assets/images/resource/immigration.jpg";
import two12 from "../../assets/images/resource/goodgamer.jpg";
import two13 from "../../assets/images/resource/nuguhome.jpg";
import two14 from "../../assets/images/resource/leevision.jpg";
import two15 from "../../assets/images/resource/clearleeperfectaesthetics.jpg";
import two16 from "../../assets/images/resource/patriotstreetfighter.jpg";
import two17 from "../../assets/images/resource/zeesy.jpg";
import two18 from "../../assets/images/resource/khusa.jpg";
import two19 from "../../assets/images/resource/metallisresourcess.jpg";
import two20 from "../../assets/images/resource/bluegreentraining.jpg";
import two21 from "../../assets/images/resource/miningnewsdaily.jpg";
import two22 from "../../assets/images/resource/henleyglobal.png";
import two23 from "../../assets/images/resource/microsmallcap.jpg";
import two24 from "../../assets/images/resource/remotejobs.jpg";
import two25 from "../../assets/images/resource/goodgamercorp.jpg";
// import two26 from "../../assets/images/resource/listeo.png";
// import two27 from "../../assets/images/resource/nimbusweb.png";
// import two28 from "../../assets/images/resource/microcapwatch.png";
// import two29 from "../../assets/images/resource/althaqafah.png";
import two30 from "../../assets/images/resource/kingsofkombucha.jpg";
import two31 from "../../assets/images/resource/zolucky.jpg";
import two32 from "../../assets/images/resource/woofhousepty.jpg";
import two33 from "../../assets/images/resource/sneakerdouble.jpg";
import two34 from "../../assets/images/resource/omnutritionist.jpg";
import two35 from "../../assets/images/resource/straightforwardlistings.jpg";
import two36 from "../../assets/images/resource/citizenshipinvest.jpg";
import two37 from "../../assets/images/resource/montereytouringvehicles.jpg";
import two38 from "../../assets/images/resource/everee.jpg";
import two39 from "../../assets/images/resource/spacesuites.jpg";
import two40 from "../../assets/images/resource/richtv.jpg";
const Projects = () => {
pageTitle('Projects');
// const projrctData = [
// ];
return (
<>
<HeaderOne></HeaderOne>
<div className="project__page p_relative see__pad">
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two5} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Lolly Prop</h4>
            <p>Property Investment Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two6} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Ameeneng</h4>
            <p>Vehicle Rentel Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two7} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Tegan Lighting</h4>
            <p>Home Interior Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two17} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Zee Sy</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>

<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two18} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Khussa</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2024</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two19} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Metallis Resources</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two20} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Blue Green Training</h4>
            <p>Shopify Ecommerce Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two21} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Mining News Daily</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>

  <div className="row">
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="portfolio__block p_relative">
        <div className="portfolio__image">
          <figure>
            <img src={two22} alt="" style={{ height: '467px', width: '410px' }} />
          </figure>
        </div>
        <div className="lower__content p_absolute">
          <div className="protfolio__text">
            <div className="text__block">
              <h4>Henley Partners</h4>
              <p>Agency Websites</p>
            </div>
            <div className="text__block_two">
              <h5>2022</h5>
            </div>
          </div>
          {/* <div className="protfolio__button">
            <Link to="/projects" className="theme-btn theme-btn-one">
              See More<i className="icon-02"></i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>

    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="portfolio__block p_relative">
        <div className="portfolio__image">
          <figure>
            <img src={two23} alt="" style={{ height: '467px', width: '410px' }} />
          </figure>
        </div>
        <div className="lower__content p_absolute">
          <div className="protfolio__text">
            <div className="text__block">
              <h4>Thompson and Co Solicitors</h4>
              <p>Property Management Websites</p>
            </div>
            <div className="text__block_two">
              <h5>2023</h5>
            </div>
          </div>
          {/* <div className="protfolio__button">
            <Link to="/projects" className="theme-btn theme-btn-one">
              See More<i className="icon-02"></i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>

    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="portfolio__block p_relative">
        <div className="portfolio__image">
          <figure>
            <img src={two24} alt="" style={{ height: '467px', width: '410px' }} />
          </figure>
        </div>
        <div className="lower__content p_absolute">
          <div className="protfolio__text">
            <div className="text__block">
              <h4>Property Inventory Network</h4>
              <p>Property Management Websites</p>
            </div>
            <div className="text__block_two">
              <h5>2023</h5>
            </div>
          </div>
          {/* <div className="protfolio__button">
            <Link to="/projects" className="theme-btn theme-btn-one">
              See More<i className="icon-02"></i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>

    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="portfolio__block p_relative">
        <div className="portfolio__image">
          <figure>
            <img src={two25} alt="" style={{ height: '467px', width: '410px' }} />
          </figure>
        </div>
        <div className="lower__content p_absolute">
          <div className="protfolio__text">
            <div className="text__block">
              <h4>Manchester Property Group</h4>
              <p>Property Management Websites</p>
            </div>
            <div className="text__block_two">
              <h5>2023</h5>
            </div>
          </div>
          {/* <div className="protfolio__button">
            <Link to="/projects" className="theme-btn theme-btn-one">
              See More<i className="icon-02"></i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two30} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Kings Of Kombucha</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two31} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Zolucky</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two32} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Woof House</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2024</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two33} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Sneaker Double</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two34} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Om Nutritionist</h4>
            <p>Doctors WordPress Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two35} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Straight Forward Listings</h4>
            <p>Property Investment Website</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two36} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Citizen Shipinvest</h4>
            <p>Property Investment Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two37} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Monterey Touring Vehicles</h4>
            <p>Vehicle Rental Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two38} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Everee</h4>
            <p>Payroll Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two39} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Space Suites</h4>
            <p>Hotel Booking Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two40} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Rich TV</h4>
            <p>Stocks Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two8} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Clinix Imagerie</h4>
            <p>Doctors WordPress Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two9} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Cates London</h4>
            <p>Property Investment Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two10} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Ucc Bh</h4>
            <p>Food Hotel Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two12} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Good Goodamer</h4>
            <p>Gaming Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two13} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Nugu Home</h4>
            <p>Shopify Ecommerce Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two16} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Patriot Street Fighter</h4>
            <p>Ecommerce Websites in WordPress</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two11} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Immigration Advice</h4>
            <p>Consultant Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2022</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two14} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Leevision</h4>
            <p>Doctors WordPress Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2024</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two15} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Clearlee Perfect Aesthetics</h4>
            <p>Doctors WordPress Websites</p>
          </div>
          <div className="text__block_two">
            <h5>2024</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Set For Set</h4>
            <p>Shopify E-Commerce Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two2} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>JDMAXX</h4>
            <p>WordPress E-Commerce Website</p>
          </div>
          <div className="text__block_two">
            <h5>2024</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two3} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Flowersnow</h4>
            <p>WordPress E-commerce Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12">
    <div className="portfolio__block p_relative">
      <div className="portfolio__image">
        <figure>
          <img src={two4} alt="" style={{ height: '467px', width: '410px' }} />
        </figure>
      </div>
      <div className="lower__content p_absolute">
        <div className="protfolio__text">
          <div className="text__block">
            <h4>Astons</h4>
            <p>Property Investment Website</p>
          </div>
          <div className="text__block_two">
            <h5>2023</h5>
          </div>
        </div>
        {/* <div className="protfolio__button">
          <Link to="/projects" className="theme-btn theme-btn-one">
            See More<i className="icon-02"></i>
          </Link>
        </div> */}
      </div>
    </div>
  </div>
</div>

</div>

<FooterOne></FooterOne>
</>
);
};
export default Projects;