import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/digital-marketing.jpg";
import basebg from "../../assets/images/resource/base.png";
import Contact from '../HomeOne/contact';

const DigitalMarketing = () => {
    pageTitle('Digital Marketing');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Web Development', slug: '/web-development' },
        { id: 2, title: 'Digital Marketing', slug: '/digital-marketing' },
        { id: 3, title: 'SEO Marketing', slug: '/seo-marketing' },
        { id: 4, title: 'Graphic Designing', slug: '/graphic-designing' },
      ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/digital-marketing' ? 'active' : ''}>
                <Link to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (
        <>
            <HeaderOne />
            {/* <BreadCrumb /> */}
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 hiding-image">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4>All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                {/* <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us For Any Inquiry</h3>
                                    <div className="services__icon">
                                        <i className="icon-20"></i>
                                    </div>
                                    <p>Need help? Email us:</p>
                                    <h4><a href="mailto:info@siteguiders.com">info@siteguiders.com</a></h4>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                            <div>
                                <h2 style={{ marginBottom: '20px' }}>Digital Marketing</h2>
                            </div>
                                <figure>
                                    <img src={service} alt="" />
                                </figure>
                                <h2>Transform Your Business with <span>Digital Marketing</span></h2>
                                <p>
                                    In today's digital world, having a strong online presence is essential for any business. Our Digital Marketing services are designed to help your business grow by increasing visibility, driving traffic, and converting leads into loyal customers. Whether you're looking to improve your SEO rankings, run targeted ad campaigns, or engage with your audience on social media, we've got you covered.
                                </p>
                                <p>
                                    Our team of experts specializes in creating tailored marketing strategies that align with your business goals. We focus on understanding your target audience, crafting compelling content, and using data-driven insights to optimize your campaigns. With our comprehensive approach, we ensure that every aspect of your digital marketing efforts works together to deliver the best possible results.
                                </p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Data Analysis</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Copywriting</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Content Marketing</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li><Link to="#"><i className="icon-04"></i> Advertising</Link></li>
                                    </div>
                                </div>
                                <p>
                                    Whether you're a small business looking to establish a digital presence or a large enterprise seeking to enhance your online marketing strategy, we provide the expertise and tools you need to succeed. Let us help you connect with your audience and drive your business forward in the digital age.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact></Contact>
            <FooterOne />
        </>
    );
};

export default DigitalMarketing;