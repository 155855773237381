import React from 'react';
import choose1 from "../../../assets/images/resource/choose-01.png";
import choose2 from "../../../assets/images/resource/choose-02.png";
// import choose3 from "../../../assets/images/resource/choose-02.png";
// import choose4 from "../../../assets/images/resource/choose-02.png";

const Choose = () => {
    return (
        <section className="choose__us p_relative" style={{ marginBottom: '120px' }}>
            <div className="choose__us__data">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block">
                                <figure>
                                    <img src={choose1} alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block__right">
                                <div className="title__data">
                                    {/* <div className="sub__title">
                                        <h4>Why choose us</h4>
                                    </div> */}
                                    <div className="title">
                                        <h2>We Provide Your Best Work </h2>
                                    </div>
                                </div>
                                <div className="team__data">
                                    <div className="team__data__left hiding-image">
                                        <figure>
                                            <img src={choose2} alt="" />
                                        </figure>
                                        
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Awesome Results :</h3>
                                        <p>At our company, we deliver outstanding results that exceed expectations. Our team combines expertise and creativity to drive success for your projects.</p>
                                    </div>
                                </div>
                                <div className="team__data">
                                    <div className="team__data__left hiding-image">
                                        <img src={choose2} alt="" />
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Keep you in the Loop :                                        </h3>
                                        <p>We believe in transparent communication and keeping you informed every step of the way. Our team will provide regular updates and ensure you’re always aware of your project's progress.</p>
                                    </div>
                                </div>
                                <div className="team__data three">
                                    <div className="team__data__left hiding-image">
                                        <img src={choose2} alt="" />
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Enhanced Performance :</h3>
                                        <p>We focus on maximizing the performance of your projects by utilizing the latest technologies and best practices. Our dedicated team continuously optimizes processes to ensure reliable results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Choose;